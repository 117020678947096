/* eslint import/prefer-default-export: 0 */

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth, Hub } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import Cookies from 'js-cookie';
import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';
import { config, COOKIE } from '../Utils/constants';
import { formatErrorMessage } from '../Utils/Helpers';
import {
  GET_CLINIC_CODE_V1,
  GET_GROUP_INFO,
  GET_GROUP_V2,
  GET_PERMISION_BY_ROLE,
  GET_ROLES_BY_GROUP_V1,
  GET_USER_ROLES_BY_GROUP,
} from '../GraphQL/Queries';

class AuthService {
  constructor() {
    this.user = undefined;
    this.role = undefined;
    this.permissions = undefined;
    this.group = undefined;
    this.groupId = undefined;
    this.groupCreatedAt = undefined;
    this.availableGroups = [];
    this.userName = undefined;
    this.ihrClinic = false;
    this.httpLink = createHttpLink({
      uri: config.nodeAPI,
    });
    this.authLink = this.getAuthLink();
    this.setClient();
    this.isAuthenticated = new BehaviorSubject(null);
    this.toastConfig = {
      position: 'bottom-center',
      theme: 'colored',
      hideProgressBar: true,
    };
    this.roles = [];
    const sessionExpired = localStorage.getItem('session-expired');
    const userUnauthorized = localStorage.getItem('user-unauthorized');
    const patientTriedToLogin = localStorage.getItem('patient-tried-to-login');
    if (sessionExpired) {
      localStorage.removeItem('session-expired');
      toast.error('The session has expired.', this.toastConfig);
    } else if (userUnauthorized) {
      localStorage.removeItem('user-unauthorized');
      toast.error('User is not authorized.', this.toastConfig);
    } else if (patientTriedToLogin) {
      localStorage.removeItem('patient-tried-to-login');
      toast.error(
        'We no longer support access to the portal. However, your Skiin Connected Life App account is unaffected and you may continue to use the app as usual.',
        this.toastConfig,
      );
    }

    Hub.listen('auth', data => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });
  }

  onAuthEvent(payload) {
    if (payload.event === 'tokenRefresh') {
      this.logout('Token Expired. Log in again');
    }
  }

  setPermissions = async currentRole => {
    const response = await this.client.query({
      query: GET_PERMISION_BY_ROLE,
      variables: {
        groupId: this.groupId,
        role: currentRole,
      },
      fetchPolicy: 'no-cache',
    });
    if (response?.data?.getPermissionsByRoleV1) {
      this.permissions = response?.data?.getPermissionsByRoleV1;
      if (this.getPermission('getRolesByGroupV1')) this.roles = await this.fetchRolesByGroup();
    }
  };

  getPermission = api => {
    if (!this.permissions) return false;
    const allowedAccess = this.permissions.filter(permission => permission.resourceName === api);
    return !!allowedAccess.length;
  };

  getAuthLink() {
    return setContext((_, { headers }) => {
      const token = this.user?.signInUserSession?.accessToken?.jwtToken;
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
  }

  completeLogin = async resp => {
    return new Promise((resolve, reject) => {
      const accessToken = resp.signInUserSession.accessToken.jwtToken;
      const refreshToken = resp.signInUserSession.refreshToken.token;
      const {
        accessTokenExpireTimestamp,
        refreshTokenExpireTimestamp,
        sub: id,
      } = resp.signInUserSession.idToken.payload;

      Auth.currentAuthenticatedUser()
        .then(async resp2 => {
          this.user = resp2;
          this.availableGroups = this.getAvailableGroups();
          this.group = await this.getGroup();
          this.groupId = await this.getGroup(true);
          await this.fetchGroupInfo();
          this.role = await this.getRole();
          // if (this.role === 'patient') this.groupId = await this.getGroupIdV2();
          if (this.role === 'patient') {
            this.logout('patient-tried-to-login');
            window.location.reload();
            return resolve(false);
          }
          await this.fetchClinicCode();
          this.userName = this.getName();
          this.authLink = this.getAuthLink();
          this.setClient();

          return resolve({
            accessToken,
            refreshToken,
            accessTokenExpireTimestamp,
            refreshTokenExpireTimestamp,
            id,
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  login(email, password) {
    return new Promise((resolve, reject) => {
      Auth.signIn({
        username: email.trimEnd(),
        password,
      })
        .then(resp => {
          if (resp) {
            if (resp.challengeName === 'SOFTWARE_TOKEN_MFA') {
              this.loggedUser = resp;
              resolve({ status: 'requireMFA' });
            } else {
              this.completeLogin(resp).then(res => {
                resolve(res);
              });
            }
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  clearLocalData = () => {
    // Clear Cookies
    Cookies.remove(COOKIE.GROUP_NAME);
    Cookies.remove(COOKIE.GROUP_ID);
    // Clear local storage
    localStorage.removeItem('eventlog-filter-date');
    localStorage.removeItem('filter-patients');
    localStorage.removeItem('my-organization-user-filter');
    localStorage.removeItem('event-logs-filter-patients');
    localStorage.removeItem('eventlog-filter-date');
    localStorage.removeItem('event-logs-filter');
    localStorage.removeItem('selected-symptom');
    localStorage.removeItem('ecg-hours');
  };

  async logout(reason = '') {
    try {
      await Auth.signOut();
      this.user = undefined;
      this.authLink = this.getAuthLink();
      this.setClient();
      this.isAuthenticated.next(false);
      this.role = '';
      this.permissions = undefined;
      this.group = await this.getGroup();
      this.groupId = await this.getGroup(true);
      await this.fetchGroupInfo();
      await this.fetchClinicCode();
      this.userName = this.getName();
      this.clearLocalData();
      console.log('User logged out!');
      if (reason === 'sessionExpired') localStorage.setItem('session-expired', true);
      else if (reason === 'unauthorized') localStorage.setItem('user-unauthorized', true);
      else if (reason === 'patient-tried-to-login') localStorage.setItem('patient-tried-to-login', true);
      return true;
    } catch (error) {
      console.error(error, 'error signing out: ');
    }
    return false;
  }

  forgotPassword = async email => {
    try {
      await Auth.forgotPassword(email);
    } catch (er) {
      if (er.code === 'UserNotFoundException') toast.error('Email not registed.', this.toastConfig);
      else toast.error(er.message.split('Username').join('Email'), this.toastConfig);
      return false;
    }
    return true;
  };

  resetPassword = async (email, code, password) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      return true;
    } catch (er) {
      console.error('Error calling reset password', er);
      alert(formatErrorMessage(er.message));
      return false;
    }
  };

  fetchRolesByGroup = async () => {
    const response = await this.client.query({
      query: GET_ROLES_BY_GROUP_V1,
      variables: {
        groupId: this.groupId,
      },
      fetchPolicy: 'no-cache',
    });
    if (response?.data?.getRolesByGroupV1) {
      return response.data.getRolesByGroupV1;
    }
    return [];
  };

  getRole = async () => {
    if (this.groupId && this.user) {
      try {
        const response = await this.client.query({
          query: GET_USER_ROLES_BY_GROUP,
          variables: {
            groupId: this.groupId,
            userId: this.user.username,
          },
          fetchPolicy: 'no-cache',
        });
        if (response?.data?.getUserRolesByGroupV1 && response?.data?.getUserRolesByGroupV1.length) {
          await this.setPermissions(response.data.getUserRolesByGroupV1[0].roleName);
          return response.data.getUserRolesByGroupV1[0].roleName;
        }
      } catch (er) {
        this.permissions = undefined;
        return 'patient';
      }
    }
    this.permissions = undefined;
    return 'patient';
  };

  getAvailableGroups() {
    if (!this.user) return [];

    const parsedString = JSON.parse(this.user.attributes['custom:groupId']);

    const groups = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key in parsedString) {
      groups.push({
        id: parsedString[key],
        name: key,
      });
    }

    if (process.env.NODE_ENV === 'development') return groups;

    // hide 'myant' group
    return groups.filter(group => group.name !== 'myant');
  }

  getGroup = (getId = false) => {
    if (this.user) {
      if (Cookies.get(COOKIE.GROUP_NAME) && Cookies.get(COOKIE.GROUP_ID)) {
        return getId ? Cookies.get(COOKIE.GROUP_ID) : Cookies.get(COOKIE.GROUP_NAME);
      }

      const parsedString = JSON.parse(this.user.attributes['custom:groupId']);

      let group = null;
      let groupId = null;

      if (process.env.NODE_ENV === 'development') {
        [group] = Object.keys(parsedString);
        groupId = parsedString[Object.keys(parsedString)[0]];
      } else {
        // To filter out 'myant' group on production
        // https://masteringjs.io/tutorials/fundamentals/filter-key#:~:text=JavaScript%20objects%20don't%20have,()%20function%20as%20shown%20below.
        const filteredGroups = Object.keys(parsedString)
          .filter(key => !key.includes('myant'))
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: parsedString[key] });
          }, {});
        [group] = Object.keys(filteredGroups);
        groupId = filteredGroups[Object.keys(filteredGroups)[0]];
      }
      if (group) Cookies.set(COOKIE.GROUP_NAME, group, { expires: parseInt(COOKIE.GROUP_INFO_TTL, 10) });
      if (groupId) Cookies.set(COOKIE.GROUP_ID, groupId, { expires: parseInt(COOKIE.GROUP_INFO_TTL, 10) });

      return getId ? groupId : group;
    }
    return undefined;
  };

  fetchGroupInfo = async () => {
    if (this.groupId) {
      try {
        const response = await this.client.query({
          query: GET_GROUP_INFO,
          variables: {
            id: this.groupId,
          },
          fetchPolicy: 'no-cache',
        });
        if (response?.data?.getGroupInfo) {
          this.groupCreatedAt = response.data.getGroupInfo.createdAt;
          this.ihrClinic = response.data.getGroupInfo.flagIHR;
        }
      } catch (er) {
        this.groupCreatedAt = undefined;
        this.ihrClinic = false;
      }
    } else {
      this.groupCreatedAt = undefined;
      this.ihrClinic = false;
    }
  };

  isIHRClinic = () => {
    return this.ihrClinic;
  };

  fetchClinicCode = async () => {
    if (this.groupId && (this.role === 'admin' || this.role === 'DeskAdmin')) {
      try {
        const response = await this.client.query({
          query: GET_CLINIC_CODE_V1,
          variables: {
            groupId: this.groupId,
          },
          fetchPolicy: 'no-cache',
        });
        if (response?.data?.getClinicCodeV1) {
          this.cliniccode = response.data.getClinicCodeV1.code;
        }
      } catch (er) {
        this.cliniccode = undefined;
      }
    } else {
      this.cliniccode = undefined;
    }
  };

  getName = () => {
    if (this.user) {
      return `${this.user.signInUserSession?.idToken?.payload['custom:firstName']} ${this.user.signInUserSession?.idToken?.payload['custom:lastName']}`;
    }
    return '';
  };

  getGroupIdV2 = async () => {
    try {
      const response = await this.client.query({
        query: GET_GROUP_V2,
        fetchPolicy: 'no-cache',
      });
      if (response?.data?.getGroupV2?.length) {
        this.patientGroupId = response.data.getGroupV2[0].id;
        return undefined;
      }
      this.patientGroupId = undefined;
      return undefined;
    } catch (er) {
      this.patientGroupId = undefined;
      return undefined;
    }
  };

  updateGroupInfo = async () => {
    this.group = await this.getGroup();
    this.groupId = await this.getGroup(true);
  };

  async checkUserAuthenticated() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
    } catch (err) {
      this.user = undefined;
    }

    if (this.user) {
      this.availableGroups = this.getAvailableGroups();
      this.group = await this.getGroup();
      this.groupId = await this.getGroup(true);
      await this.fetchGroupInfo();
      this.role = await this.getRole();
      if (this.role === 'patient') this.groupId = await this.getGroupIdV2();
      await this.fetchClinicCode();
      this.userName = this.getName();
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  isAuthenticated() {
    return !!this.user;
  }

  sessionExpired = message => {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      console.log('Logging out beacause of', message);
      this.logout(message);
      window.location.reload();
    }, 1000);
  };

  setClient() {
    const logoutLink = onError(({ networkError }) => {
      if (networkError && networkError.statusCode === 401) {
        this.sessionExpired('sessionExpired');
      }
    });
    const links = logoutLink.concat(this.authLink.concat(this.httpLink));
    this.client = new ApolloClient({
      link: links,
      cache: new InMemoryCache(),
      credentials: 'include',
      fetchOptions: {
        mode: 'no-cors',
      },
    });
  }

  getClient() {
    return this.client;
  }

  getTOTPSecretCode = async () => {
    if (!this.user) return undefined;
    const secretCode = await Auth.setupTOTP(this.user);
    return secretCode;
  };

  getSecretQRString = async () => {
    const code = await this.getTOTPSecretCode();
    if (!code) return undefined;
    return `otpauth://totp/${this.user.attributes.email}?secret=${code}&issuer=Myant Inc: Skiin Portal`;
  };

  updateAWSPreferedMFA = async () => {
    // Set TOTP as the preferred MFA method.
    let mfaType = 'TOTP';
    if (this.user.preferredMFA === 'SOFTWARE_TOKEN_MFA') mfaType = 'NOMFA';
    await Auth.setPreferredMFA(this.user, mfaType);
  };

  verifyTotpToken = async code => {
    try {
      await Auth.verifyTotpToken(this.user, code);
      return {
        success: true,
      };
    } catch (e) {
      return e.message === 'Code mismatch'
        ? {
            error: true,
            message: 'Invalid code, please try again.',
          }
        : {
            error: true,
            message: 'Something went wrong, please try again.',
          };
    }
  };

  confirmSignIn = async (code, mfaType = 'SOFTWARE_TOKEN_MFA') => {
    try {
      const resp = await Auth.confirmSignIn(
        this.loggedUser, // Return object from Auth.signIn()
        code, // Confirmation code
        mfaType, // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
      this.loggedUser = undefined;
      const res = await this.completeLogin(resp);
      return {
        success: true,
        response: res,
      };
    } catch (e) {
      return e.message === 'Code mismatch'
        ? {
            error: true,
            message: 'Invalid code, please try again.',
          }
        : {
            error: true,
            message: 'Something went wrong, please try again.',
          };
    }
  };
}

export const AWS = (function () {
  let instance;
  function createInstance() {
    return new AuthService();
  }
  return {
    getInstance() {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();
